.PhotoUpdateDateBulkModal {
  form {
    display: grid;
    gap: 1rem;
  }

  .time_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
  }
}
