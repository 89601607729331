.CommonSearchFilter {
  display: inline-flex;
  align-items: center;

  padding: .25rem .75rem;
  border-radius: 1rem;
  border: 1px solid white;

  font-size: .85rem;

  i {
    margin-left: .5rem;
    cursor: pointer;

    &:hover {
      opacity: .5;
    }
  }
}
