.YoutubeDownloadModal {
  .modal-dialog {
    max-width: 100vh;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  pre {
    max-height: 60vh;
  }
}
