.PhotoUploadList {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: -0.5rem;

    .status {
      font-size: 2rem;
    }
  }

  .progress {
    margin-bottom: 1rem;
  }
}
