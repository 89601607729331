.PhotoUploadItem {
  .dim {
    background-color: rgba(0, 0, 0, 0.75);
  }

  i {
    font-size: 2rem;
  }

  .progress {
    width: 75%;
  }
}
