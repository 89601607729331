.MenuAppsView {
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  i {
    font-size: 2rem;
    margin-bottom: .5rem;
  }

  span {
    font-size: .75rem;
    font-weight: 200;
  }
}
