.CommonViewerPage {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .swiper_container {
    flex: 1 1 0;
    position: relative;
  }

  .swiper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;

    video, img {
      max-height: 100%;
    }
  }

  .CommonViewerInfoContainer {
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;
    background: var(--hyunsub-header-bg);
    max-height: 0;

    & > .container > .wrapper {
      position: relative;
      padding: 0.75rem 0;
    }

    &.show {
      max-height: 360px;
    }

    .close_btn {
      position: absolute;
      height: 36px;
      width: 36px;
      right: 0;
      font-size: 1.5rem;

      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;

      cursor: pointer;
    }
  }
}
