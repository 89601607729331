@import "/src/breakpoints.scss";

.PhotoDetailView {
  .file_name {
    @include media-breakpoint-up(md) {
      margin-bottom: 1rem;
      font-size: 2rem;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
    }
  }

  .info {
    margin-top: 1rem;
    gap: 0.5rem;

    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
    }

    .info_item {
      & > *:first-child {
        color: var(--hyunsub-text-hover);
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
          font-size: 1rem;
        }
        @include media-breakpoint-down(md) {
          font-size: .85rem;
        }
      }

      & > *:last-child {
        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
        }
        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }
      }
    }
  }

  .fa-pen {
    font-size: 0.75rem;
    @include media-breakpoint-up(md) {
      margin-left: 0.5rem;
    }
    @include media-breakpoint-down(md) {
      margin-left: 0.25rem;
    }
  }

  .cursor_pointer {
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
}
