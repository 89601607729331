.CommonNavBar {
  border-bottom: 1px solid var(--hyunsub-divider-color);
  background-color: var(--hyunsub-header-bg);
  height: var(--header_height_desktop);

  .hyunsub_container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;

    .title {
      font-family: 'Train One', serif;
      font-size: 2rem;
    }

    .menus {
      white-space: nowrap;
      font-size: 1.5rem;

      a {
        display: inline-flex;
        align-items: center;
        margin-left: 1.5rem;
      }

      i {
        margin-right: .5rem;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: -1rem;

      .header_icon_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        width: var(--header_height_desktop);
        height: var(--header_height_desktop);
        font-size: 1.5rem;
      }

      .fa-user-circle {
        font-size: 2rem;
      }
    }
  }
}
