.DiaryDetailPage {
  .date {
    font-size: 1.5rem;
  }

  .letters {
    font-size: .75rem;
    text-align: center;
  }

  .query {
    background-color: yellow;
    color: black;
  }
}
