@import '/src/breakpoints.scss';

.ImageCarousel {
  img {
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    .swiper-slide {
      width: 360px;
      height: 360px;
    }

    img {
      object-fit: cover;
    }
  }
  @include media-breakpoint-down(md) {

  }
}
