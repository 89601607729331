@import '/src/breakpoints.scss';

.PhotoMetadataListView {
  overflow: scroll;

  @include media-breakpoint-up(md) {
    padding: 0 calc((100vw - 720px) / 2 + 0.75rem);
  }
  @include media-breakpoint-up(lg) {
    padding: 0 calc((100vw - 960px) / 2 + 0.75rem);
  }
  @include media-breakpoint-up(xl) {
    padding: 0 calc((100vw - 1140px) / 2 + 0.75rem);
  }
  @include media-breakpoint-up(xxl) {
    padding: 0 calc((100vw - 1320px) / 2 + 0.75rem);
  }
}
