.DriveRenameFileList {
  width: 100%;
  table-layout: fixed;

  tr {
    height: 40px;
  }

  th {
    border-top: 1px solid var(--hyunsub-divider-color);
    border-bottom: 1px solid var(--hyunsub-divider-color);
  }

  td {
    border-bottom: 1px solid var(--hyunsub-divider-color);
  }
}
