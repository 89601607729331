.CommonTabBar {
  height: calc(var(--hyunsub_tabbar_height) + var(--hyunsub_app_bottom_safe_area_height));
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1005;
  padding-bottom: var(--hyunsub_app_bottom_safe_area_height);

  display: flex;

  border-top: 1px solid var(--hyunsub-bg);
  background-color: var(--hyunsub-header-bg);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  a {
    flex: 1 1 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  i {
    font-size: 1.3rem;
    margin-bottom: .3rem;
  }

  span {
    font-size: .7rem;
    font-weight: 100;
  }
}
