.DutchSpendItem {
  padding: .5rem 0;

  .section_1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .section_2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .content {
    font-size: 1.1rem;
  }

  .location {
    font-size: 0.8rem;
    color: var(--hyunsub-text-hover);
  }

  .amount {
    white-space: nowrap;
  }

  .date {
    font-size: 0.7rem;
    color: var(--hyunsub-text-hover);
  }
}
