.GeneticCombinationItem {
  display: flex;
  padding: .5rem 0;

  .index {
    flex: 0 0 64px;
  }

  .gender {
    flex: 0 0 64px;
  }

  .types {
    flex: 1 1 0;
  }

  .name {
    flex: 1 1 0;
  }

  .typeHistory {
    flex: 1 1 0;
  }

  .probability {
    flex: 1 1 0;
  }
}
