#LoadingPageDim {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;

  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}
