.FileUploadZone {
  position: relative;

  .dropzone {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: none;
    font-size: 5rem;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.hover {
    .dropzone {
      display: flex;
    }
  }
}
