.CommonImagePage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-transform-wrapper {
    height: 100vh;
  }
}
