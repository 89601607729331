.MenuProfileView {
  display: flex;
  align-items: center;

  .profile {
    width: 48px;
    height: 48px;
    margin-right: 1rem;
    border-radius: 24px;
    background-color: var(--hyunsub-content-bg);
  }

  .username {
    font-size: 1.5rem;
    margin-right: auto;
  }
}
