.ComicEpisodePreviewItem {
  position: relative;
  padding: .5rem 0;

  span {
    position: relative;
    z-index: 100;
  }

  .history {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 99;
  }
}
