@import "/src/breakpoints.scss";

.AlbumPreviewView {
  .name {
    @include media-breakpoint-up(md) {
      margin-top: 0.5rem;
      font-size: 1rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: 0.25rem;
      font-size: 14px;
    }
  }
}
