@import "/src/breakpoints.scss";

.PhotoDownloadItem {
  border-bottom: 1px solid var(--hyunsub-divider-color);

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0.5rem 0;
    text-align: center;

    .index {
      flex: 0 0 64px;
    }

    .content {
      flex: 1 1 0;
      text-align: start;
    }

    .status {
      flex: 0 0 100px;
    }

    .date {
      flex: 0 0 100px;
    }

    .download {
      flex: 0 0 100px;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
    padding: 0.5rem 0;

    &:first-child {
      border-top: 1px solid var(--hyunsub-divider-color);
    }

    .status {
      width: 100px;
    }

    .date {
      color: var(--hyunsub-text-hover);
    }
  }
}
