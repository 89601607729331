.PhotoInfoSection {
  display: grid;
  grid-template-columns: 1fr 3fr;
  font-size: 0.75rem;

  .btn_group {
    margin-top: 0.5rem;
    grid-column-start: 1;
    grid-column-end: 3;
    gap: 0.5rem;
    display: flex;

    .btn {
      flex: 1 1 0;
    }
  }
}
