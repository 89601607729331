.MobileHeader {
  height: calc(var(--header_height_mobile) + var(--hyunsub_app_top_safe_area_height));
  padding-top: var(--hyunsub_app_top_safe_area_height);

  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1000;

  border-bottom: 1px solid #232323;
  background-color: var(--hyunsub-header-bg);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  &.transparent {
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    background-color: rgba(0, 0, 0, 0);
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 1.5rem;
    overflow: hidden;

    i {
      margin-left: .75rem;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-left: .75rem;
    }
  }

  .buttons {
    display: flex;
    align-items: center;

    & > i, .MobileHeaderMoreButton > i {
      cursor: pointer;
      font-size: 1.5rem;
      width: var(--header_height_mobile);
      height: var(--header_height_mobile);

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
