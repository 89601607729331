@import '/src/breakpoints.scss';

.ComicPreviewView {
  display: block;

  .thumbnail {
    @include media-breakpoint-up(md) {
      border-radius: 0.25rem;
    }
  }

  .title {
    word-wrap: break-word;
    word-break: break-all;

    @include media-breakpoint-down(md) {
      font-size: 0.75rem;
      margin-top: .25rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1rem;
      margin-top: .5rem;
    }
  }
}
