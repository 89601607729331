.DriveRenameControl {
  display: flex;
  flex-direction: column;

  .btn_bar {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .option {
    margin-top: 0.5rem;

    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    .input-group {
      width: inherit;
    }

    & > * {
      flex: 0 0 auto;
    }
  }
}
