.DriveExplorerFileItem {
  height: 40px;
  user-select: none;

  &:hover {
    background-color: var(--hyunsub-title-bg);
  }

  &.selected {
    background-color: var(--hyunsub-content-bg);
  }

  .check {
    i {
      cursor: pointer;

      &:hover {
        color: var(--hyunsub-text-hover);
      }
    }
  }

  .name {
    a:hover {
      text-decoration: underline;
    }

    input {
      background-color: transparent;
      color: white;
      border: 1px solid var(--bs-primary);
      width: 100%;
    }
  }

  .size {
    font-size: .8rem;
    white-space: nowrap;
  }

  .date {
    width: 180px;
    font-size: .8rem;
    font-family: 'Roboto Mono';
    white-space: nowrap;
  }
}
