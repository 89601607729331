.GeneticCreatureItem {
  display: flex;
  padding: .5rem 0;
  text-align: center;

  & > div {
    flex: 1 1 0;
  }
}

.GeneticCreatureHead {
  font-weight: bold;
}
