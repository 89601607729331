.PhotoUploadZone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  &.hover {
    background-color: var(--hyunsub-hover-bg);
  }

  &:hover {
    background-color: var(--hyunsub-hover-bg);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
      font-size: 4rem;
    }

    .drag {
      font-size: 2rem;
    }

    .or {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  input {
    opacity: 0;
    cursor: pointer;
  }
}
