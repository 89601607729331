#AppsIndex {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 240px;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  i {
    font-size: 3rem;
  }

  span {
    font-size: 1.5rem;
  }
}
