@import 'src/breakpoints.scss';

.SelectIndicator {
  line-height: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(0, 0, 0, 0.7) 100%
  );

  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-down(md) {
    font-size: 1.25rem;
  }

  &.selected {
    display: block;
    background-color: rgba(255, 255, 255, 0.5);
  }

  @include media-breakpoint-up(md) {
    &:not(.selected) i:hover {
      color: var(--hyunsub-text-hover);
    }
  }

  & > i {
    @include media-breakpoint-up(md) {
      padding: 0.4rem;
    }
    @include media-breakpoint-down(md) {
      padding: 0.4rem;
    }
  }
}
