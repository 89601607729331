.DiaryPreviewItem {
  padding: 0.5rem 0;

  .title_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-top;
    margin-bottom: 0.25rem;

    .date {
      word-break: keep-all;
      white-space: nowrap;
      font-size: 1.25rem;
    }
  }

  .summary {
    margin-bottom: 0.25rem;
    color: var(--hyunsub-text-hover);
  }

  .query {
    background-color: yellow;
    color: black;
  }
}
