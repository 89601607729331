.CommonContainer {
  &.is_mobile {
    padding-top: calc(var(--header_height_mobile) + var(--hyunsub_app_top_safe_area_height));
    padding-left: .5rem;
    padding-right: .5rem;

    &.noContainer {
      padding-left: 0;
      padding-right: 0;
    }

    & > .content {
      padding-top: .75rem;
      padding-bottom: .75rem;
    }
  }

  &.is_desktop {
    & > .content {
      padding-top: .5rem;
      padding-bottom: 2rem;
    }
  }
}

#TabBar + div #CommonContainer {
  &.is_mobile {
    padding-bottom: calc(var(--hyunsub_tabbar_height) + var(--hyunsub_app_bottom_safe_area_height));
  }
}
