.DutchRecordDetailView {
  .content {
    font-size: 1.5rem;
  }

  .label {
    margin-top: .5rem;
    margin-bottom: .25rem;
    font-size: .75rem;
    color: var(--hyunsub-text-hover);
  }
}
