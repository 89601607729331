.DriveViewerModal {
  .modal-dialog {
    align-items: center;
    justify-content: center;

    max-width: initial;
  }

  .modal-content {
    align-items: center;
    justify-content: center;

    width: initial;
    max-height: 80vh;
    background-color: transparent!important;
    border-width: 0!important;
    padding: 0;

    overflow: scroll;

    & > * {
      border-radius: var(--bs-modal-border-radius);
    }

    .spinner_wrapper {
      width: 100vw;
    }

    img, video {
      max-height: inherit;
      max-width: inherit;
    }

    audio {
      width: 512px;
    }

    pre {
      width: 100vw;
      height: 100%;
      padding: 1rem;
      background-color: var(--hyunsub-content-bg);
      overflow: scroll;
    }

    object {
      height: 80vh;
      padding: 0;
    }
  }
}
