@import '/src/breakpoints.scss';

:root {
  scroll-behavior: auto!important;

  --hyunsub-bg: #141414;
  --hyunsub-header-bg: rgba(36, 36, 36, .8);
  --hyunsub-title-bg: #323232;
  --hyunsub-content-bg: #454545;
  --hyunsub-hover-bg: #333;

  --hyunsub-text-color: white;
  --hyunsub-text-hover: #AAA;

  --hyunsub-border-color-primary: #DDD;
  --hyunsub-border-color-secondary: #777;
  --hyunsub-divider-color: rgba(255, 255, 255, 0.25);

  --header_height_desktop: 64px;
  --header_height_mobile: 48px;
  --hyunsub_tabbar_height: 60px;

  --hyunsub_app_bottom_safe_area_height: env(safe-area-inset-bottom);
  --hyunsub_app_top_safe_area_height: env(safe-area-inset-top);
}

html, body, #root {
  width: 100vw;
  min-height: 100vh;
  color: var(--hyunsub-text-color);
}

body, #root {
  background-color: var(--hyunsub-bg);
}

a {
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: none;
    color: white;
  }
}

img {
  object-fit: cover;
}

pre {
  margin-bottom: 0;
}

button {
  color: black; // https://stackoverflow.com/q/74853859
}

hr {
  @include media-breakpoint-down(md) {
    margin: 0.5rem 0 !important;
  }
}

// ===============================================================
// custom class

input.form-control, textarea.form-control {
  background-color: var(--bs-gray-dark);
  color: white;

  &:focus {
    background-color: var(--bs-gray);
    color: white;
  }

  &:read-only {
    background-color: var(--bs-gray);
    color: white;
  }
}

select.form-select {
  background-color: var(--bs-gray-dark);
  color: white;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

@include media-breakpoint-up(md) {
  .move_up_on_hover {
    transition: transform .3s;

    &:hover {
      transform: translateY(-6px);
    }
  }
}

.gray_on_hover:hover {
  cursor: pointer;
  color: var(--hyunsub-text-hover);
}

.gray_bg_hover:hover {
  cursor: pointer;
  background-color: var(--hyunsub-hover-bg);
}

.flex_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide_scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hyunsub_border {
  border-top: 1px solid var(--hyunsub-divider-color);
  &:last-of-type {
    border-bottom: 1px solid var(--hyunsub-divider-color);
  }
}

.hyunsub_border_flush {
  border-bottom: 1px solid var(--hyunsub-divider-color);
  &:last-of-type {
    border-bottom: 0px solid var(--hyunsub-divider-color);
  }
}

.ratio-9x16 > * {
  --bs-aspect-ratio: 141.4%;
}

// Grid
@for $i from 1 through 6 {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      .row-col-#{$breakpoint}-#{$i} {
        grid-template-columns: repeat($i, minmax(0, 1fr));
      }
    }
  }

  .row-col-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr));
  }
}

// Container
.hyunsub_container {
  margin-right: auto;
  margin-left: auto;
}

@each $breakpoint in map-keys($container-max-widths) {
  @include media-breakpoint-up($breakpoint) {
    .hyunsub_container {
      max-width: map-get($container-max-widths, $breakpoint);
    }
  }
}

// ===============================================================
// customize bootstrap

.card {
  border-color: var(--hyunsub-border-color-primary);
  overflow: hidden;

  .card-header {
    background-color: var(--hyunsub-title-bg);
    border-bottom-color: var(--hyunsub-border-color-primary);
  }

  .card-body {
    background-color: var(--hyunsub-content-bg);
  }
}

.list-group {
  --bs-list-group-bg: var(--hyunsub-content-bg);
  --bs-list-group-color: var(--hyunsub-text-color);
  --bs-list-group-border-color: var(--hyunsub-border-color-secondary);

  .list-group-item {
    &:hover:not(.active) {
      background-color: var(--hyunsub-title-bg);
    }
  }
}

.modal {
  --bs-border-color: rgba(255, 255, 255, 0.5);
  --bs-modal-border-color: rgba(255, 255, 255, 0.5);

  .modal-content {
    background-color: var(--hyunsub-content-bg);

    .btn-close {
      opacity: 1;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    }

    .modal-header, .modal-footer {
      background-color: var(--hyunsub-title-bg);
    }
  }
}

.pagination {
  --bs-pagination-bg: var(--bs-gray-dark);
  --bs-pagination-color: white;
  --bs-pagination-hover-bg: var(--bs-gray);
  --bs-pagination-focus-bg: var(--bs-gray);
  --bs-pagination-hover-color: white;
  --bs-pagination-focus-color: white;
  --bs-pagination-active-border-color: var(--bs-pagination-border-color);
  --bs-pagination-font-size: 1rem;

  @include media-breakpoint-up(md) {
    --bs-pagination-font-size: 1rem;
  }
  @include media-breakpoint-down(md) {
    --bs-pagination-font-size: .75rem;
  }
}

.dropdown {
  .dropdown-menu {
    background-color: var(--bs-gray-dark);
  }

  .dropdown-item {
    color: white;

    &:hover {
      background-color: var(--bs-gray);
    }
  }
}

.progress {
  --bs-progress-bar-transition: width 0.1s ease!important;
}

// ===============================================================
// customize react-select

.select__control {
  background-color: var(--bs-gray-dark)!important;
  cursor: pointer!important;
}

.is-invalid .select__control {
  border-color: var(--bs-danger)!important;
}

.select__input {
  color: white!important;
}

.select__menu {
  background-color: var(--bs-gray-dark)!important;
  border: white 1px solid;
}

.select__option {
  background-color: var(--bs-gray-dark)!important;
  cursor: pointer!important;
}

.select__option--is-focused {
  background-color: var(--bs-gray)!important;
}

.select__single-value {
  color: white!important;
}

.select--is-disabled {
  opacity: 0.5;
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .container-width {
      width: map-get($container-max-widths, $breakpoint);
    }
  }
}
