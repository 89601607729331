.DesktopHeader {
  margin-top: .5rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: .25rem 0;

  &.isTop {
    border-bottom: 1px solid var(--hyunsub-divider-color);
    background-color: var(--hyunsub-header-bg);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
  }
}
