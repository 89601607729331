.FriendDetailView {
  dt {
    font-size: 1.5rem;
    margin-bottom: .5rem;
  }

  .tag_container, .meet_container {
    display: flex;
    gap: .5rem;
    font-size: 1.25rem;
  }
}
