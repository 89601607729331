@import "/src/breakpoints.scss";

.DiaryDetailPhotoList {
  .title_container {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      margin-bottom: 0.25rem;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 0.5rem;
    }

    .title {
      cursor: pointer;
      @include media-breakpoint-down(md) {
        font-size: 1.25rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }
    }

    .more {
      cursor: pointer;
      margin-left: auto;
      color: var(--bs-secondary);
      @include media-breakpoint-down(md) {
        font-size: 0.75rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
    }
  }
}
