.CommonCalendar {
  width: auto!important;
  border-radius: .5rem;

  .sat {
    color: var(--bs-primary);
  }

  .react-calendar__navigation {
    margin-bottom: 0;
  }

  .react-calendar__tile--active {
    background: #ccc !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: black;

    & > abbr {
      text-decoration: none;
      -webkit-text-decoration: none;
      cursor: auto;
    }
  }
}
