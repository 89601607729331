@import '/src/breakpoints.scss';

.CommonDescription {
  &.onEdit {
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    i.fas.fa-pen {
      display: inline-block;
      margin-left: 0.25rem;
    }
  }

  label {
    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 0.75rem;
    }
    color: var(--hyunsub-text-hover);

    i.fas.fa-pen {
      display: none;
    }
  }

  .value {
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
    color: white;
  }
}

.CommonDescriptionGroup {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    column-gap: 1.5rem;
    row-gap: 1rem;
  }
  @include media-breakpoint-down(md) {
    column-gap: 1rem;
    row-gap: 0.25rem;
  }
}
