.my_table {
  display: table;
  width: 100%;

  .table_header {
    height: 36px;
    word-break: keep-all;
    white-space: nowrap;
    font-weight: bold;
  }

  .table_row {
    display: table-row;

    &:last-of-type {
      .cell {
        border-bottom: 1px solid var(--hyunsub-divider-color);
      }
    }
  }

  .cell {
    display: table-cell;
    border-top: 1px solid var(--hyunsub-divider-color);
    vertical-align: middle;

    &.check {
      width: 28px;
      padding-left: 12px;
    }

    &.icon {
      width: 48px;
      text-align: center;
    }

    &.size {
      width: 100px;
      padding-right: 12px;
      text-align: right;
    }

    &.date {
      width: 160px;
      padding-right: 12px;
      text-align: right;
    }

    .sort_btn {
      cursor: pointer;

      &:hover {
        color: var(--hyunsub-text-hover);
      }
    }
  }
}

.DriveExplorerFileList {
  .loading {
    height: 240px;
    border-bottom: 1px solid var(--hyunsub-divider-color);
  }

  .table_footer {
    height: 36px;
    border-bottom: 1px solid var(--hyunsub-divider-color);
  }
}
